import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CustomBreadcrumb.module.css";

function CustomBreadcrumb({ items = [] }) {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="flex-start" mb="20px">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {items.map((item, index) => (
          <div
            key={index}
            className={item.to && index < items.length - 1 && styles.hover}
            onClick={() => {
              if (item.to && index < items.length - 1) {
                navigate(item.to);
              }
            }}
          >
            {item.title}
          </div>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

export default CustomBreadcrumb;
