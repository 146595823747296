import { Box } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomBreadcrumb } from "../../components/Breadcrumb";
import Product from "../../components/Product/Product";
import ProductSkeleton from "../../components/Product/ProductSkeleton";
import { getFeaturedProducts, getSpecialProducts } from "../../services/api/products";
import styles from "./ShowBarahiFeatured.module.css";

function ShowBarahiAll() {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSetup, setPageSetup] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    page_size: 1,
    to: "",
    from: "",
  });
  const viewProductRef = useRef(null);

  const [title, setTitle] = useState(location.state?.category || "");

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    if (location.state?.category === "Featured Products") {
      getFeaturedProducts(page, abort.signal)
        .then(async (res) => {
          setProducts([...products, ...res.data.products]);
          setPageSetup({
            total: res.data.total,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            page_size: res.data.page_size,
            to: res.data.to,
            from: res.data.from,
          });
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false); //error occurs in strict mode due to 2 times api call
        });
    } else if (location.state?.category === "Barahi Special Products") {
      getSpecialProducts(page, abort.signal)
        .then(async (res) => {
          setProducts([...products, ...res.data.products]);
          setPageSetup({
            total: res.data.total,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            page_size: res.data.page_size,
            to: res.data.to,
            from: res.data.from,
          });
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false); //error occurs in strict mode due to 2 times api call
        });
    }
    return () => {
      abort.abort();
    };
  }, [page]);

  return (
    <section className={cx("section", styles.product)}>
      <div className="container" ref={viewProductRef}>
        <h2 className={cx("h2", "section-title", styles.sectionTitle)}>{title}</h2>
        <CustomBreadcrumb
          items={
            location.state?.category === "Featured Products"
              ? [
                  { title: "Home", to: "/" },
                  { title: "Featured Products", to: "/featured-products/" },
                ]
              : [
                  { title: "Home", to: "/" },
                  { title: "Barahi Special Products", to: "/barahi-special/" },
                ]
          }
        />

        {pageSetup.total > 0 && (
          <div className={styles.items}>{pageSetup.total} items found in storage</div>
        )}
        <div className={cx(styles.gridList, "grid-list")}>
          {!(page === 1 && loading) &&
            products.map((item, index) => <Product item={item} key={item.id} />)}
        </div>
        <div>
          {loading ? (
            <div className={cx(styles.gridList, "grid-list")} style={{ paddingTop: "10px" }}>
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          ) : (
            pageSetup.current_page !== pageSetup.last_page && (
              <Box display="flex" justifyContent="center" alignItems="center" mt="20px">
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  className="outlined-btn"
                >
                  Load More
                </button>
              </Box>
            )
          )}
        </div>
      </div>
    </section>
  );
}

function ShowBarahiFeatured() {
  return <ShowBarahiAll />;
}

export default ShowBarahiFeatured;
