import cx from "classnames";
import React from "react";
import { FaViber } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={cx(styles.container, "container")}>
          <div className={styles.footerBrand}>
            <div className={styles.logo}>Barahi Foods</div>
            <ul className={styles.socialList}>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100093073477983"
                  target="_blank"
                  className={styles.socialLink}
                >
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/819060949954?text=Is anyone available to chat?"
                  className={styles.socialLink}
                >
                  <ion-icon name="logo-whatsapp"></ion-icon>
                </a>
              </li>
              {/* <li>
                <a href="#" className={styles.socialLink}>
                  <ion-icon name="logo-instagram"></ion-icon>
                </a>
              </li> */}
              <li>
                <a
                  href="https://www.tiktok.com/@barahistoreshinokubo?_t=8axcOrJpEeX&_r=1"
                  target="_blank"
                  className={styles.socialLink}
                >
                  <ion-icon name="logo-tiktok"></ion-icon>
                </a>
              </li>
              <li>
                <a
                  href="viber://chat?number=819060949954"
                  target="_blank"
                  className={styles.socialLink}
                >
                  <FaViber />
                </a>
              </li>
            </ul>

            <ul className={styles.footerList}>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="location"></ion-icon>
                  </div>
                  Tokyo, Shinjuku, Hyakunincho 2-10-9 101
                </a>
              </li>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="call"></ion-icon>
                  </div>
                  03-3363-1145, 090-6094-9954
                </a>
              </li>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="mail"></ion-icon>
                  </div>
                  barahistore@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <ul className={styles.footerList}>
            <li>
              <p className={styles.footerListTitle}>Company</p>
            </li>
            <li>
              <Link to="/policies/privacy/" className={styles.footerLink}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/delivery/" className={styles.footerLink}>
                Delivery Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/refund/" className={styles.footerLink}>
                Refund Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/terms/" className={styles.footerLink}>
                Terms & Conditions
              </Link>
            </li>
          </ul>
          <ul className={styles.footerList}>
            <li>
              <p className={styles.footerListTitle}>Find Us Here</p>
            </li>
            <div className={styles.location}>
              <div
                className={styles.overlay}
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.987813499757!2d139.6997403!3d35.7019175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d2f16645651%3A0x5632115a7c48bbd!2sBarahi%20Food%20and%20Spice(%20Halal%20food)!5e0!3m2!1sen!2snp!4v1679311329861!5m2!1sen!2snp"
                  )
                }
              ></div>
              <iframe
                sandbox="allow-top-navigation allow-scripts allow-forms"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.987813499757!2d139.6997403!3d35.7019175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d2f16645651%3A0x5632115a7c48bbd!2sBarahi%20Food%20and%20Spice(%20Halal%20food)!5e0!3m2!1sen!2snp!4v1679311329861!5m2!1sen!2snp"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </ul>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={cx(styles.container, "container")}>
          <p className={styles.copyright}>
            &copy;2023 Barahi Foods. All Rights Reserved.
          </p>
          <p className={styles.poweredBy}>
            Powered By:
            <span>
              <a href="https://twoacesolutions.com/" target="_blank">
                Two Ace Solutions Pvt. Ltd.
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
