import { gapi } from "gapi-script";
import React, { useEffect } from "react";
// import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { loginGoogle } from "../../services/api/auth";
import { setCookie } from "../../utils/cookie/cookies";
import "./css.css";

const LoginForm = ({ successLogin }) => {
  useEffect(() => {
    function start(param) {
      gapi.client.init({
        clientId: process.env.REACT_APP_GMAIL_APP_ID,

        scope: "",
      });

      gapi.load("client:auth2", start);
    }
  }, []);

  ///new setup login

  // const responseFacebook = (response) => {
  //   loginFacebook({ token: response.accessToken }).then((response) => {
  //     setCookie("token", response.data.token);
  //     localStorage.setItem("token", response.data.token);
  //     successLogin(response.data);
  //   });
  // };

  const responseGoogle = (response) => {
    loginGoogle({ token: response.accessToken }).then((response) => {
      setCookie("token", response.data.token);
      localStorage.setItem("token", response.data.token);
      successLogin(response.data);
    });
  };

  return (
    <>
      <div>
        {/* <div>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID} //APP ID NOT CREATED YET
            fields="name,email,picture"
            callback={responseFacebook}
            onFailure={(a) => console.log("fff")}
            autoLoad={false}
            cssClass="facebook"
            icon={
              <div className="facebook-icon">
                <ion-icon name="logo-facebook"></ion-icon>
              </div>
            }
          />
        </div> */}
        <div>
          <GoogleLogin
            clientId={process.env.REACT_APP_GMAIL_APP_ID}
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            onFailure={(a) => console.log("fff")}
            cookiePolicy="single_host_origin"
            autoLoad={false}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="google"
              >
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#000" fill-rule="evenodd">
                    <path
                      d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                      fill="#EA4335"
                    ></path>
                    <path
                      d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                      fill="#34A853"
                    ></path>
                    <path fill="none" d="M0 0h18v18H0z"></path>
                  </g>
                </svg>
                Login with Google
              </button>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default LoginForm;
