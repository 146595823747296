import cx from "classnames";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/store";
import styles from "./Offers.module.css";

function Offers() {
  const [state, dispatch] = useContext(StoreContext);
  const { homeState } = state;
  const navigate = useNavigate();
  return (
    <section className={cx("section", styles.offers)}>
      <div className="container">
        <ul className={cx(styles.offersList, "has-scrollbar")}>
          {homeState.banners.map((item) => (
            <li className={styles.offersItem} key={item.id}>
              <div className={styles.offersCard}>
                <figure className={styles.cardBanner}>
                  <img
                    src={process.env.REACT_APP_SERVER_URL + item.image}
                    width="292"
                    height="230"
                    loading="lazy"
                    alt="card"
                    className="w-100"
                  />
                </figure>
                <div className={styles.cardContent}>
                  <p className={styles.cardSubtitle}>{item.super_title}</p>
                  <h3 className={cx("h3", styles.cardTitle)}>{item.title}</h3>
                  <div
                    className={cx(styles.btn, "btn", "btnPrimary")}
                    onClick={() => {
                      navigate(
                        `/all-products?category_id=${item.category_id}&title=${item.product_categories.title}`
                      );
                    }}
                  >
                    Shop Now
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Offers;
