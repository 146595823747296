import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { getAllOrders } from "../../services/api/order";
import styles from "./Orders.module.css";

const getPaymentMode = (mode) => {
  switch (mode) {
    case 1:
      return <div>COD</div>;
    case 2:
      return <div>Stripe</div>;
    case 3:
      return <div>Paypal</div>;
    case 4:
      return <div>Paystack</div>;
    case 5:
      return <div>Creditcard</div>;
    default:
      return <div>COD</div>;
  }
};
const Orders = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState();

  const [page, setPage] = useState(0);
  const total = useRef(null);
  const pageSize = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpen = (order) => {
    setCurrentOrder(order);
    setOpen(true);
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "Order Placed";
      case 2:
        return "Preparing Items";
      case 3:
        return "On delivery";
      case 4:
        return "Completed";
      case 5:
        return "Canceled";
    }
  };

  const handleClose = () => setOpen(false);
  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getAllOrders(page + 1, abort.signal)
      .then((res) => {
        setOrderData(res.data.orders);
        total.current = res.data.total;
        pageSize.current = res.data.page_size;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    return () => {
      abort.abort();
    };
  }, [page]);

  return (
    <div className={cx(styles.container, "container")}>
      <h2 className={cx("h2", "section-title", styles.sectionTitle)}>Orders</h2>
      <div>
        <TableContainer className={styles.table}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Order Id</TableCell>
                <TableCell align="right">Order Date</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Payment</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <CircularProgress />
            ) : (
              <TableBody>
                {orderData.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <div>
                        <div>{row.unique_order_id}</div>
                        <div className={styles.link} onClick={() => handleOpen(row)}>
                          View Details
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {new Date(row.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{getOrderStatus(row.orderstatus_id)}</TableCell>
                    <TableCell align="right">{getPaymentMode(row.payment_mode_id)}</TableCell>
                    <TableCell align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[pageSize.current]}
                  colSpan={5}
                  count={total.current}
                  rowsPerPage={pageSize.current}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        PaperProps={{
          sx: {
            minHeight: 400,
          },
        }}
        onClose={handleClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ padding: "10px 10px" }} id="alert-dialog-title">
          Order Details{" "}
          <div className={styles.gridContainer}>
            <div className={styles.gridTitle}>Name</div>
            <div className={styles.gridTitle}>Quantity</div>
            <div className={styles.gridTitle}>Price</div>
            <div className={styles.gridTitle}>Total</div>
          </div>
        </DialogTitle>
        <div className={styles.content}>
          {currentOrder?.orderitems.map((item, index) => (
            <div className={styles.grid} key={item.id}>
              <div className={styles.gridContainer}>
                <div>{item.name}</div>
                <div>x {item.quantity}</div>
                <div>¥ {item.price}</div>
                <div>¥ {item.quantity * item.price}</div>
              </div>
            </div>
          ))}
          <div className={styles.footer}>
            <div>Delivery Charge: </div>
            <div>¥{currentOrder?.delivery_charge}</div>
            <div>Total: </div>
            <div>¥{currentOrder?.total}</div>
            <div>Delivery Address: </div>
            <div>{currentOrder?.address}</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Orders;
