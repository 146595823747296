import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { logout } from "../../services/api/auth";
import { StoreContext } from "../../store/store";
import * as userActions from "../../store/user/actionTypes";
import { clearCookie } from "../../utils/cookie/cookies";
import Cart from "../Cart/Cart";
import Login from "../Login/Login";
import styles from "./Header.module.css";

function Header() {
  const scrollPosition = useScrollPosition();

  const navigate = useNavigate();

  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState, homeState } = state;

  const [cartActive, setCartActive] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const searchRef = useRef(null);

  const open = Boolean(anchorEl);

  const handleProfileClick = (event) => {
    if (userState.isLoggedIn) {
      setAnchorEl(event.currentTarget);
      // setShowLoginDialog(true);
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    setDropdownActive(false);
    let searchValue = searchRef.current.value;
    if (searchValue.length > 0) {
      navigate(`/all-products?search=${searchValue}`);
    } else {
      navigate("/");
    }
  };

  const handleLogout = () => {
    logout()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        clearCookie("token");
        dispatch({
          type: userActions.LOGGED_OUT,
        });
        toast.success("Logged out Successfully");
        handleClose();
      });
  };

  return (
    <header>
      <div className={cx(styles.topbar, scrollPosition > 400 && styles.topActive)}>
        <div className={cx("container", styles.topFlex)}>
          <p>Delivering all over japan</p>
          <ul className={styles.socialList}>
            <li>
              <a href="#" className={styles.socialLink}>
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>
            <li>
              <a href="#" className={styles.socialLink}>
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={cx(styles.headerMain, scrollPosition > 400 && styles.topActive)}>
        <div
          className={cx(
            styles.overlay,
            navActive && styles.overlayActive,
            dropdownActive && styles.overlayActive
          )}
          onClick={() => {
            setDropdownActive(false);
            setNavActive(false);
          }}
        ></div>
        <div className={cx(styles.container, "container")}>
          <Link to="/" className={styles.logo}>
            <img src="/images/logo.png" />
          </Link>
          <button
            className={styles.hamburger}
            aria-label="Open Menu"
            onClick={() => setNavActive(true)}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </button>
          <div
            className={styles.headerSearchContainer}
            style={dropdownActive ? { zIndex: 4 } : null}
          >
            <div className={styles.category} onClick={() => setDropdownActive((prev) => !prev)}>
              Select a Category
              <div>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
            </div>
            <div className={cx(styles.dropdownPanel, dropdownActive && styles.dropdownActive)}>
              <ul className={styles.dropdownPanelList}>
                {homeState.categories.map((item) => (
                  <li key={item.id} className={styles.menuTitle}>
                    <div
                      onClick={() => {
                        setNavActive(false);
                        setDropdownActive(false);
                        navigate(`/all-products?title=${item.title}&category_id=${item.id}`);
                      }}
                    >
                      {item.title}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <input
              type="text"
              name="search"
              ref={searchRef}
              className={styles.searchField}
              placeholder="Enter your product name..."
            />
            <button className={styles.searchBtn} onClick={handleSearch}>
              <ion-icon name="search-outline"></ion-icon>
            </button>
          </div>
          <div className={styles.headerUserActions}>
            <button className={styles.actionBtn} onClick={() => setCartActive(true)}>
              <ion-icon name="bag-handle-outline"></ion-icon>
              {cartState.quantity > 0 && (
                <data className={styles.btnBadge}>{cartState.quantity}</data>
              )}
            </button>
            <button className={styles.actionBtn} onClick={handleProfileClick}>
              <ion-icon name="person-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.mobileBottomNavigation}>
        <button className={styles.actionBtn} onClick={() => setNavActive(true)}>
          <ion-icon name="grid-outline"></ion-icon>
        </button>
        <button className={styles.actionBtn} onClick={() => navigate("/")}>
          <ion-icon name="home-outline"></ion-icon>
        </button>
        <button className={styles.actionBtn} onClick={() => setCartActive(true)}>
          <ion-icon name="bag-handle-outline"></ion-icon>
          {cartState.quantity > 0 && <data className={styles.btnBadge}>{cartState.quantity}</data>}
        </button>
        <button className={styles.actionBtn} onClick={handleProfileClick}>
          <ion-icon name="person-outline"></ion-icon>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>{userState.username}</MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("orders");
            }}
          >
            View all Orders
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      <nav className={cx(styles.navbar, navActive && styles.active)}>
        <div className={styles.sidebarFlex}>
          <Link to="/" className={styles.mobileLogo} onClick={() => setNavActive(false)}>
            <img src="/images/logo-primary.png" className={styles.mobileLogoImage} />
          </Link>
          <button
            className={styles.navCloseBtn}
            aria-label="Close Menu"
            onClick={() => setNavActive(false)}
          >
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <ul className={styles.navbarList}>
          <li className={styles.navTitle}>Categories</li>
          {homeState.categories.map((item, index) => (
            <li key={item.id}>
              <div
                className={styles.navbarLink}
                onClick={() => {
                  setNavActive(false);
                  setDropdownActive(false);
                  navigate(`/all-products?title=${item.title}&category_id=${item.id}`);
                }}
              >
                {item.title}
              </div>
            </li>
          ))}
        </ul>
      </nav>
      <aside className={styles.aside}>
        <div
          className={cx(styles.overlay, cartActive && styles.overlayActive)}
          onClick={() => setCartActive(false)}
        ></div>
        <Cart setCartActive={setCartActive} cartActive={cartActive} />
        {showLoginDialog && <Login open={showLoginDialog} setOpen={setShowLoginDialog} />}
      </aside>
    </header>
  );
}

export default Header;
