import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    100: "#cdccd9",
    200: "#9b99b3",
    300: "#6a678d",
    400: "#383467",
    500: "#060141",
    600: "#050134",
    700: "#040127",
    800: "#02001a",
    900: "#01000d",
  },
  secondary: {
    100: "#cdf3e1",
    200: "#9be7c3",
    300: "#69daa6",
    400: "#37ce88",
    500: "#05c26a",
    600: "#049b55",
    700: "#037440",
    800: "#024e2a",
    900: "#012715",
  },
  neutral: {
    100: "#cdedde",
    200: "#9bdbbd",
    300: "#68c89b",
    400: "#36b67a",
    500: "#04a459",
    600: "#038347",
    700: "#026235",
    800: "#024224",
    900: "#012112",
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#991e2d",
    },
    secondary: {
      main: shades.secondary[100],
    },
    neutral: {
      main: shades.neutral[100],
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontFamily: ["Fauna One", "serif"].join(","),
      fontSize: 48,
    },
    h2: {
      fontFamily: ["Fauna One", "serif"].join(","),
      fontSize: 36,
    },
    h3: {
      fontFamily: ["Fauna One", "serif"].join(","),
      fontSize: 20,
    },
    h4: {
      fontFamily: ["Fauna One", "serif"].join(","),
      fontSize: 14,
    },
    p: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: 16,
    },
  },
});
