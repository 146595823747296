export const deliveryData = [
  {
    sn: "1",
    prefecture: "Aichi",
    dsc: "1530",
    dsbw: "28",
    csc: "1850",
    csbw: "23",
  },
  {
    sn: "2",
    prefecture: "Akita",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "3",
    prefecture: "Aomori",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "4",
    prefecture: "Chiba",
    dsc: "1330",
    dsbw: "28",
    csc: "1500",
    csbw: "23",
  },
  {
    sn: "5",
    prefecture: "Ehime",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "6",
    prefecture: "Fukui",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "7",
    prefecture: "Fukuoka",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "8",
    prefecture: "Fukushima",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "9",
    prefecture: "Gifu",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "10",
    prefecture: "Gumma",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "11",
    prefecture: "Hiroshima",
    dsc: "1690",
    dsbw: "28",
    csc: "2000",
    csbw: "23",
  },
  {
    sn: "12",
    prefecture: "Hokkaido",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "13",
    prefecture: "Hyogo",
    dsc: "1690",
    dsbw: "28",
    csc: "2000",
    csbw: "23",
  },
  {
    sn: "14",
    prefecture: "Ibaraki",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "15",
    prefecture: "Ishikawa",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "16",
    prefecture: "Iwate",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "17",
    prefecture: "Kagawa",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "18",
    prefecture: "Kagoshima",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "19",
    prefecture: "Kanagawa",
    dsc: "1330",
    dsbw: "28",
    csc: "1500",
    csbw: "23",
  },
  {
    sn: "20",
    prefecture: "Kochi",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "21",
    prefecture: "Kumamoto",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "22",
    prefecture: "Kyoto",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "23",
    prefecture: "Mie",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "24",
    prefecture: "Miyagi",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "25",
    prefecture: "Miyazaki",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "26",
    prefecture: "Nagano",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "27",
    prefecture: "Nagasaki",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "28",
    prefecture: "Nara",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "29",
    prefecture: "Niigata",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "30",
    prefecture: "Oita",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "31",
    prefecture: "Okayama",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "32",
    prefecture: "Okinawa",
    dsc: "2170",
    dsbw: "28",
    csc: "2500",
    csbw: "23",
  },
  {
    sn: "33",
    prefecture: "Osaka",
    dsc: "1690",
    dsbw: "28",
    csc: "1950",
    csbw: "23",
  },
  {
    sn: "34",
    prefecture: "Saga",
    dsc: "2020",
    dsbw: "28",
    csc: "2350",
    csbw: "23",
  },
  {
    sn: "35",
    prefecture: "Saitama",
    dsc: "1350",
    dsbw: "28",
    csc: "1500",
    csbw: "23",
  },
  {
    sn: "36",
    prefecture: "Shiga",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "37",
    prefecture: "Shimane",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "38",
    prefecture: "Shizuoka",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "39",
    prefecture: "Tochigi",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "40",
    prefecture: "Tokushima",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "41",
    prefecture: "Tokyo",
    dsc: "1100",
    dsbw: "28",
    csc: "1300",
    csbw: "23",
  },
  {
    sn: "42",
    prefecture: "Tottori",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "43",
    prefecture: "Toyama",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "44",
    prefecture: "Wakayama",
    dsc: "1690",
    dsbw: "28",
    csc: "1850",
    csbw: "23",
  },
  {
    sn: "45",
    prefecture: "Yamagata",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
  {
    sn: "46",
    prefecture: "Yamaguchi",
    dsc: "1800",
    dsbw: "28",
    csc: "2100",
    csbw: "23",
  },
  {
    sn: "47",
    prefecture: "Yamanashi",
    dsc: "1590",
    dsbw: "28",
    csc: "1900",
    csbw: "23",
  },
];
