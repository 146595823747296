import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { loadStripe } from "@stripe/stripe-js";
import cx from "classnames";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { syncCart } from "../../services/api/cart";
import { getPrefectures, stripeCheckout } from "../../services/api/checkout";
import { getDiscountCode, getSavedAddress } from "../../services/api/order";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import styles from "./Checkout.module.css";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const deliveryTimes = [
  "Anytime",
  "9 to 12",
  "12 to 2",
  "2 to 4",
  "4 to 6",
  "6 to 8",
  "8 to 9",
];

const Checkout = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { cartState } = state;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [savedAddress, setSavedAddress] = useState([]);
  const [prefectures, setPrefectures] = useState([]);
  const [discountedAmount, setDiscountedAmount] = useState();
  const [billingData, setBillingData] = useState({
    name: "",
    building: "",
    room: "",
    post_code: "",
    chome: "",
    city: "",
    prefecture: "",
    delivery_time: deliveryTimes[0],
    delivery_date: null,
    paymentOption: null,
    save_address: false,
    discount_code: "",
  });

  const [paymentData, setPaymentData] = useState({
    phone_number: "",
    payment_option: "",
  });

  const handlePromoCode = () => {
    getDiscountCode(cartState.subtotal, billingData?.discount_code)
      .then((res) => {
        setDiscountedAmount(res.data.discount);
        toast.success("Successfully added promo code!!!");
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleFormSubmit = (e) => {
    let err = {};
    if (billingData.name.length === 0) {
      err["name"] = ["Name is required"];
    }
    if (billingData.post_code.length === 0) {
      err["post_code"] = ["Zip/Post Code is required"];
    }
    if (billingData.chome.length === 0) {
      err["chome"] = ["Chome is required"];
    }
    if (billingData.city.length === 0) {
      err["city"] = ["City is required"];
    }
    if (billingData.prefecture.length === 0) {
      err["prefecture"] = ["Prefecture is required"];
    }
    if (billingData.delivery_time.length === 0) {
      err["delivery_time"] = ["Delivery time is required"];
    }
    if (paymentData.phone_number.length === 0) {
      err["phone_number"] = ["Phone number is required"];
    } else if (
      paymentData.phone_number.length < 10 ||
      paymentData.phone_number.length > 11
    ) {
      err["phone_number"] = ["Phone number is not valid"];
    }

    if (paymentData.payment_option.length === 0) {
      err["payment_option"] = ["Select a payment option"];
    }
    if (selectedAddress === null) {
      err["address_option"] = [
        "Address is not selected. Please select an address.",
      ];
    }

    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }
    if (paymentData.payment_option === "card-payment") {
      makePayment(billingData, paymentData);
    } else if (paymentData.payment_option === "cod") {
      const requestBody = {
        prefered_delivery_date: billingData.delivery_date?.format("YYYY-MM-DD"),
        delivery_time: billingData.delivery_time,
        discount_amount: discountedAmount,
        discount_code: billingData.discount_code,
        save_address:
          selectedAddress !== "-1" ? false : billingData.save_address,
        user: {
          data: {
            name: billingData.name,
            phone: paymentData.phone_number,
            default_address: {
              building: billingData.building,
              chome: billingData.chome,
              room: billingData.room,
              city: billingData.city,
              post_code: billingData.post_code,
              prefecture: billingData.prefecture,
              discount_code: billingData.discount_code,
              street_address: "dfa",
              city: "csdf",
              house: "121",
              landmark: "fda",
            },
          },
        },
        orders: cartState.items.map(({ id, purchasedUnits }) => ({
          id: id,
          quantity: purchasedUnits,
        })),
      };
      navigate("/checkout/cod/", {
        state: {
          requestBody: requestBody,
          billingData: billingData,
          paymentData: paymentData,
        },
      });
    }
  };
  async function makePayment(billingData, paymentData) {
    setLoading(true);
    const stripe = await stripePromise;
    const requestBody = {
      prefered_delivery_date: billingData.delivery_date?.format("YYYY-MM-DD"),
      delivery_time: billingData.delivery_time,
      save_address: selectedAddress !== "-1" ? false : billingData.save_address,
      discount_code: billingData.discount_code,
      user: {
        data: {
          name: billingData.name,
          phone: paymentData.phone_number,
          default_address: {
            building: billingData.building,
            chome: billingData.chome,
            room: billingData.room,
            city: billingData.city,
            post_code: billingData.post_code,
            prefecture: billingData.prefecture,
            discount_code: billingData.discount_code,
          },
        },
      },
      orders: cartState.items.map(({ id, purchasedUnits }) => ({
        id: id,
        quantity: purchasedUnits,
      })),
    };

    const session = await stripeCheckout(requestBody);
    await stripe.redirectToCheckout({
      sessionId: session.data.stripe_session,
    });
  }

  useEffect(() => {
    setLoading(true);
    if (cartState.quantity > 0) {
      syncCart(cartState)
        .then((res) => {
          dispatch({
            type: cartActions.SYNC_CART,
            items: res.data.items,
            subtotal: res.data.subtotal,
          });
        })
        .catch((err) => {});
    }
    let abort = new AbortController();
    getSavedAddress(abort.signal)
      .then((res) => {
        setSavedAddress(res.data);
        if (res.data.length === 0) {
          setSelectedAddress("-1");
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });

    getPrefectures(abort.signal)
      .then((res) => {
        setPrefectures(res.data);
      })
      .catch((err) => {});
    return () => {
      abort.abort();
    };
  }, []);

  return cartState.items.length === 0 ? (
    <Navigate to="/" />
  ) : (
    <Box
      width="80%"
      m="60px auto"
      className={cx(styles.container, "container")}
    >
      <Box>
        <Box m="30px auto">
          {/* BILLING FORM */}
          <Box>
            <Typography sx={{ mb: "15px" }} fontSize="18px">
              Billing Information
            </Typography>
            <form noValidate autoComplete="off">
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  value={billingData.name}
                  onChange={(e) =>
                    setBillingData({ ...billingData, name: e.target.value })
                  }
                  error={errors["name"] !== undefined}
                  helperText={errors["name"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Phone number"
                  value={paymentData.phone_number}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/; // Only allow numbers
                    let val = e.target.value;
                    if (regex.test(val) || val === "") {
                      setPaymentData({ ...paymentData, phone_number: val });
                    }
                  }}
                  error={errors["phone_number"] !== undefined}
                  helperText={errors["phone_number"]}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Typography sx={{ mb: "15px", mt: "15px" }} fontSize="18px">
                Shipping Information
              </Typography>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <FormControl
                  error={errors["payment_option"] !== undefined}
                  sx={{ gridColumn: "span 4" }}
                >
                  <RadioGroup
                    aria-labelledby="payment-option"
                    name="payment-option"
                    onChange={(e) => {
                      // setPaymentData({ ...paymentData, payment_option: e.target.value });
                      if (e.target.value === "-1") {
                        setBillingData({
                          ...billingData,
                          building: "",
                          chome: "",
                          room: "",
                          city: "",
                          post_code: "",
                          prefecture: "",
                          prefecture_title: "",
                          save_address: false,
                        });
                        setSelectedAddress("-1");
                      } else {
                        let val = parseInt(e.target.value);
                        let obj = savedAddress.find((o) => o.id === val);
                        setSelectedAddress(e.target.value);
                        setBillingData({
                          ...billingData,
                          building: obj.building,
                          chome: obj.chome,
                          room: obj.room,
                          post_code: obj.post_code,
                          city: obj.city,
                          prefecture: obj.prefecture_id,
                          prefecture_title: obj.prefecture,
                          save_address: false,
                        });
                      }
                    }}
                  >
                    {savedAddress.map((item, index) => {
                      var room =
                        item.room && item.room.length > 0
                          ? `Room: ${item.room},`
                          : "";
                      var building =
                        item.building && item.building.length > 0
                          ? `${item.building},`
                          : "";
                      return (
                        <FormControlLabel
                          key={item.id}
                          value={item.id}
                          control={<Radio />}
                          label={`${room} ${building} ${item.chome}, ${item.city}, ${item.prefecture} ${item.post_code}`}
                        />
                      );
                    })}
                    <FormControlLabel
                      value={"-1"}
                      checked={selectedAddress === "-1"}
                      control={<Radio />}
                      label="New Address"
                    />
                  </RadioGroup>
                  <FormHelperText>{errors["address_option"]}</FormHelperText>
                </FormControl>
                {selectedAddress === "-1" && (
                  <>
                    <TextField
                      fullWidth
                      type="text"
                      label="Post Code"
                      value={billingData.post_code}
                      placeholder="example: 169-0073"
                      onChange={(e) =>
                        setBillingData({
                          ...billingData,
                          post_code: e.target.value,
                        })
                      }
                      error={errors["post_code"] !== undefined}
                      helperText={errors["post_code"]}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={prefectures}
                      getOptionLabel={(option) => option.title}
                      onChange={(e, newValue) => {
                        setBillingData({
                          ...billingData,
                          prefecture: newValue.id,
                          prefecture_title: newValue.title,
                        });
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors["prefecture"] !== undefined}
                          helperText={errors["prefecture"]}
                          label="Prefecture"
                        />
                      )}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="City, Ward"
                      placeholder="example: Shinjuku, Hyakunincho"
                      value={billingData.city}
                      onChange={(e) =>
                        setBillingData({ ...billingData, city: e.target.value })
                      }
                      error={errors["city"] !== undefined}
                      helperText={errors["city"]}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Chome, Banchi"
                      value={billingData.chome}
                      placeholder="example: 2-10-9"
                      onChange={(e) =>
                        setBillingData({
                          ...billingData,
                          chome: e.target.value,
                        })
                      }
                      error={errors["chome"] !== undefined}
                      helperText={errors["chome"]}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Building/ Apt/ House (optional)"
                      placeholder="example: Barahi Complex"
                      value={billingData.building}
                      onChange={(e) =>
                        setBillingData({
                          ...billingData,
                          building: e.target.value,
                        })
                      }
                      sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="Unit/ Room no. (optional)"
                      placeholder="example: 55"
                      value={billingData.room}
                      onChange={(e) =>
                        setBillingData({ ...billingData, room: e.target.value })
                      }
                      sx={{ gridColumn: "span 2" }}
                    />

                    <FormControlLabel
                      sx={{ gridColumn: "span 4" }}
                      control={
                        <Checkbox
                          checked={billingData.save_address}
                          onChange={(e) => {
                            setBillingData({
                              ...billingData,
                              save_address: e.target.checked,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Save Address"
                    />
                  </>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Preferred Date for Delivery (Optional)"
                    onChange={(value) =>
                      setBillingData({ ...billingData, delivery_date: value })
                    }
                    value={billingData.delivery_date}
                    shouldDisableDate={(date) =>
                      dayjs(date).isSame(dayjs(), "day")
                    }
                    disablePast
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        error={errors["date"] !== undefined}
                        helperText={errors["date"]}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormControl
                  sx={{ gridColumn: "span 2" }}
                  error={errors["delivery_time"] !== undefined}
                >
                  <InputLabel id="demo-multiple-name-label">
                    Delivery Time
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    helperText={errors["delivery_time"]}
                    value={billingData.delivery_time}
                    onChange={(e) => {
                      setBillingData({
                        ...billingData,
                        delivery_time: e.target.value,
                      });
                    }}
                    input={<OutlinedInput label="Delivery Time" />}
                    // MenuProps={MenuProps}
                  >
                    {deliveryTimes.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors["delivery_time"] && (
                    <FormHelperText id="my-helper-text">
                      {errors["delivery_time"]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Typography sx={{ mb: "15px", mt: "15px" }} fontSize="18px">
                Payment Option
              </Typography>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <FormControl
                  error={errors["payment_option"] !== undefined}
                  sx={{ gridColumn: "span 2" }}
                >
                  <RadioGroup
                    aria-labelledby="payment-option"
                    defaultValue="female"
                    name="payment-option"
                    onChange={(e) => {
                      setPaymentData({
                        ...paymentData,
                        payment_option: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="cod"
                      control={<Radio />}
                      label="Cash on Delivery"
                    />
                    <FormControlLabel
                      value="card-payment"
                      control={<Radio />}
                      label="Card Payment"
                    />
                  </RadioGroup>
                  <FormHelperText>{errors["payment_option"]}</FormHelperText>
                </FormControl>
              </Box>
              <div style={{ marginTop: "40px" }}>
                <p style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Get Discount with promo code
                </p>
                <Box
                  display="grid"
                  gap="15px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    type="text"
                    label="Promo Code"
                    value={billingData.coupon}
                    onChange={(e) =>
                      setBillingData({
                        ...billingData,
                        discount_code: e.target.value,
                      })
                    }
                  />

                  <Button
                    variant="outlined"
                    onClick={handlePromoCode}
                    style={{ width: "fit-content" }}
                  >
                    Add Promo code
                  </Button>
                </Box>
              </div>
            </form>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="20px"
        >
          {discountedAmount && (
            <Typography fontSize="18px">
              Subtotal: ¥{cartState.subtotal - discountedAmount}
            </Typography>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleFormSubmit}
            disabled={loading}
            style={{ marginLeft: "auto" }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : paymentData.payment_option === "card-payment" ? (
              "Pay with Stripe"
            ) : (
              "Place Order"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Checkout;
