import { Box } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomBreadcrumb } from "../../components/Breadcrumb";
import { NoSearchResult } from "../../components/NoSearchResult";
import Product from "../../components/Product/Product";
import ProductSkeleton from "../../components/Product/ProductSkeleton";
import { getProducts } from "../../services/api/products";
import styles from "./ShowAll.module.css";

function ShowAllInner() {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSetup, setPageSetup] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    page_size: 1,
    to: "",
    from: "",
  });
  const viewProductRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState(searchParams.get("title") || "All Products");
  const [breadcrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    let abort = new AbortController();

    if (page > 1) {
      setLoading(true);
      getProducts(
        searchParams.get("category_id") || "",
        page,
        abort.signal,
        searchParams.get("search") || ""
      )
        .then((res) => {
          setProducts([...products, ...res.data.products]);
          setPageSetup({
            total: res.data.total,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            page_size: res.data.page_size,
            to: res.data.to,
            from: res.data.from,
          });
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      abort.abort();
    };
  }, [page]);

  useEffect(() => {
    let abort = new AbortController();
    setTitle(searchParams.get("title"));
    setLoading(true);
    setPage(1);
    getProducts(
      searchParams.get("category_id") || "",
      1,
      abort.signal,
      searchParams.get("search") || ""
    )
      .then(async (res) => {
        setProducts(res.data.products);

        if (res.data.category) {
          setTitle(res.data.category.title);
        } else {
          setTitle("All Products");
        }

        setPageSetup({
          total: res.data.total,
          current_page: res.data.current_page,
          last_page: res.data.last_page,
          page_size: res.data.page_size,
          to: res.data.to,
          from: res.data.from,
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false); //error occurs in strict mode due to 2 times api call
      });

    if (searchParams)
      return () => {
        abort.abort();
      };
  }, [searchParams]);

  useEffect(() => {
    let breadcrumbItems = [
      { title: "Home", to: "/" },
      { title: "Products", to: "/all-products/" },
    ];
    if (searchParams.get("category_id")) {
      breadcrumbItems.push({ title: title, to: null });
    }
    setBreadcrumb(breadcrumbItems);
  }, [title]);

  return (
    <section className={cx("section", styles.product)}>
      <div className="container" ref={viewProductRef}>
        <h2 className={cx("h2", "section-title", styles.sectionTitle)}>{title}</h2>
        <CustomBreadcrumb items={breadcrumb} />
        {searchParams.get("search") && (
          <div>Showing Results for "{searchParams.get("search")}"</div>
        )}

        {pageSetup.total > 0 && (
          <div className={styles.items}>{pageSetup.total} items found in storage</div>
        )}
        <div className={cx(styles.gridList, "grid-list")}>
          {!(page === 1 && loading) &&
            products.map((item, index) => <Product item={item} key={item.id} />)}
        </div>
        <div>
          {loading ? (
            <div className={cx(styles.gridList, "grid-list")} style={{ paddingTop: "10px" }}>
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          ) : products.length === 0 ? (
            <>
              <NoSearchResult />
            </>
          ) : (
            pageSetup.current_page !== pageSetup.last_page && (
              <Box display="flex" justifyContent="center" alignItems="center" mt="40px">
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  className="outlined-btn"
                >
                  Load More
                </button>
              </Box>
            )
          )}
        </div>
      </div>
    </section>
  );
}

function ShowAll() {
  return <ShowAllInner />;
}

export default ShowAll;
