import { baseRequest } from "./base";

const getProductCategories = async (signal) => {
  var response = await baseRequest({
    url: "/api/product-category",
    method: "GET",
    signal: signal,
  });
  return response;
};

const getHomeProducts = async (signal) => {
  var response = await baseRequest({
    url: "/api/home-products",
    method: "GET",
    signal: signal,
  });
  return response;
};

const getProducts = async (categoryId, page, signal, search = "") => {
  var response = await baseRequest({
    url: `/api/products?category_id=${categoryId}&page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getRelatedProducts = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/product/${id}/similar`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getProductById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/product/${id}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getFeaturedProducts = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/featured/products?page=${page}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getSpecialProducts = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/special/products?page=${page}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getRecentProducts = async (signal) => {
  var response = await baseRequest({
    url: `/api/recent/products`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getDeliveryCharge = async (orders, prefecture, discount_code, signal) => {
  var postApi = "";
  for (let index = 0; index < orders.length; index++) {
    postApi += `orders[${index}][id]=${orders[index].id}&orders[${index}][quantity]=${orders[index].purchasedUnits}`;
    if (index + 1 < orders.length) {
      postApi = postApi + "&";
    }
  }
  postApi += `&prefecture=${prefecture}`;
  var response = await baseRequest({
    url: `/api/delivery-charge?${postApi}&discount_code=${discount_code}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

export {
  getProductCategories,
  getProducts,
  getFeaturedProducts,
  getSpecialProducts,
  getDeliveryCharge,
  getRecentProducts,
  getProductById,
  getRelatedProducts,
  getHomeProducts,
};
