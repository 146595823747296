import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, IconButton } from "@mui/material";
import cx from "classnames";
import React from "react";
import styles from "./Cart.module.css";

const CartItem = ({ item, handleDecrement, handleIncrement, removeItem }) => {
  return (
    <div className={styles.panelCard}>
      <figure className={styles.itemBanner}>
        <img
          src={process.env.REACT_APP_SERVER_URL + item.image}
          width="46"
          height="46"
          loading="lazy"
          alt="dummy"
        />
      </figure>
      <div className={styles.secondSection}>
        <div className={styles.detailsSection}>
          <p className={styles.itemTitle}>{item.title}</p>
          <p className={styles.price}>Price: ¥{item.price1}</p>
          <div className={styles.cartBtn}>
            <IconButton
              className={cx(styles.iconBtn, styles.removeIcon)}
              onClick={() => {
                if (item.purchasedUnits > 1) {
                  handleDecrement(item);
                }
              }}
            >
              <RemoveIcon className={styles.icon} />
            </IconButton>
            <p className={styles.cartNumber}>x{item.purchasedUnits}</p>
            <IconButton
              className={cx(styles.iconBtn, styles.addIcon)}
              onClick={() => {
                handleIncrement(item);
              }}
            >
              <AddIcon className={styles.icon} />
            </IconButton>
          </div>
        </div>
        <div className={styles.flexbox}>
          <button
            className={styles.itemCloseBtn}
            aria-label="Remove item"
            onClick={() => {
              removeItem(item);
            }}
          >
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <Box>
            <span className={styles.itemValue}>¥{item.purchasedUnits * item.price1}</span>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
