import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ProductDetails } from "./components/ProductDetails";
import NotFound from "./pages/404/NotFound";
import CashOnDelivery from "./pages/Checkout/CashOnDelivery";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutCancel from "./pages/Checkout/CheckoutCancel";
import CheckoutSuccess from "./pages/Checkout/CheckoutSuccess";
import { Home } from "./pages/Home";
import Orders from "./pages/Orders/Orders";
import { Delivery, Privacy, Refund, Terms } from "./pages/Policies";
import { ShowAll } from "./pages/ShowAll";
import { ShowBarahiFeatured } from "./pages/ShowBarahiFeatured";
import { isLogged } from "./services/api/auth";
import { syncCart } from "./services/api/cart";
import { getHomeItems } from "./services/api/home";
import * as cartActions from "./store/cart/actionTypes";
import * as homeActions from "./store/home/actionTypes";
import { StoreContext } from "./store/store";
import * as userActions from "./store/user/actionTypes";
import { clearCookie } from "./utils/cookie/cookies";
import { ToastContainer } from "react-toastify";
import { FixedCart } from "./components/FixedCart";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState } = state;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartState));
  }, [cartState]);

  useEffect(() => {
    let abort = new AbortController();
    isLogged(abort.signal)
      .then((res) => {
        if (res.data.logged_in) {
          dispatch({
            type: userActions.LOGGED_IN,
            payload: {
              ...res.data,
              is_wholesale: false,
            },
          });
        } else {
          clearCookie("token");
          dispatch({ type: userActions.LOGGED_OUT });
        }
      })
      .catch((err) => {});
    setLoading(true);
    getHomeItems(abort.signal)
      .then((res) => {
        dispatch({
          type: homeActions.FETCH_ALL,
          categories: res.data.categories,
          sliders: res.data.sliders,
          banners: res.data.secondary_banners,
        });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));

    if (cartState.quantity > 0) {
      syncCart(cartState)
        .then((res) => {
          dispatch({
            type: cartActions.SYNC_CART,
            items: res.data.items,
            subtotal: res.data.subtotal,
          });
        })
        .catch((err) => {});
    }

    return () => {
      abort.abort();
    };
  }, []);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/" element={<ProductDetails />} />

          <Route path="/all-products/" element={<ShowAll />} />
          <Route path="/featured-products/" element={<ShowBarahiFeatured />} />
          <Route path="/barahi-specials/" element={<ShowBarahiFeatured />} />
          {userState.isLoggedIn && (
            <>
              <Route path="/orders/" element={<Orders />} />
              <Route path="/checkout/success/" element={<CheckoutSuccess />} />
              <Route path="/checkout/canceled/" element={<CheckoutCancel />} />
              <Route path="/checkout/" element={<Checkout />} />
              <Route path="/checkout/cod/" element={<CashOnDelivery />} />
            </>
          )}
          <Route path="/policies/privacy/" element={<Privacy />} />
          <Route path="/policies/refund/" element={<Refund />} />
          <Route path="/policies/delivery/" element={<Delivery />} />
          <Route path="/policies/terms/" element={<Terms />} />
          <Route path="/all-products/:id/" element={<ProductDetails />} />
          <Route path="/not-found/" element={<NotFound />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <FixedCart />
        {/* <GoToTop /> */}
      </Router>
    </div>
  );
}

export default App;
